import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/layout';

Vue.use(VueRouter)

const routes = [
  {
    path:'/login',
    name:'login',
    component: () => import('@/views/login'),
    meta:{
      title:"登录页"
    },
    hidden:true
  },
  {
    path: '/userInfo',
    name: 'userInfo',
    component: Layout,
    redirect: '/userInfo',
    children: [
      {
        path: '/userInfo',
        component: () => import('@/views/userInfo'),
        meta: { title: '基础信息'},
        hidden:true
      },
      {
        path: '/groupEdit',
        component: () => import('@/views/userInfo/groupEdit'),
        meta: { title: '项目公司编辑'},
        hidden:true
      }
    ]
  },

  {
    path: '/changePwd',
    name: 'changePwd',
    component: Layout,
    redirect: '/changePwd',
    children: [
      {
        path: '/changePwd',
        component: () => import('@/views/changePwd'),
        meta: { title: '密码修改'},
        hidden:true
      }
    ]
  },

  {
    path: '/',
    name: 'home',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        component: () => import('@/views/home'),
        meta: { title: '组织首页',icon:"iconfont icon-shouye1"}
      }
    ]
  },

  {
    path: '/company',
    name: 'company',
    component: Layout,
    meta: { title: '总包部',icon:"iconfont icon-xiangmu1"},
    redirect: '/manager-company',
    children: [
      {
        path: 'manager-company',
        component: () => import('@/views/company/manager-company.vue'),
        meta: { title: '管理总包部',keepAlive:true}
      },
      {
        path: 'company-create',
        component: () => import('@/views/company/company-create.vue'),
        meta: { title: '总包部创建'},
        hidden: true
      },
      {
        path: 'company-change',
        component: () => import('@/views/company/company-change.vue'),
        meta: { title: '总包部编辑'},
        hidden: true
      },
      {
        path: 'company-detail',
        name: 'company-detail',
        component: () => import('@/views/company/company-detail.vue'),
        meta: { title: '总包部详情'},
        hidden: true
      },
    ]
  },
  {
    path: '/list',
    component: Layout,
    meta: { title: '报事',icon:"iconfont icon-gaiicon-1"},
    redirect: 'list/list-ting',
    children: [
      {
        path: 'list-ting',
        component: () => import('@/views/list/list-ting.vue'),
        meta: { title: '报事列表',keepAlive: true}
      },
      {
        path: 'data-statisical',
        component: () => import('@/views/list/data-statisical.vue'),
        meta: { title: '数据统计'}
      },
      {
        path: 'list-detail',
        name:"list-detail",
        component: () => import('@/views/list/list-detail'),
        meta: { title: '报事详情'},
        hidden: true
      },
    ]
  },


  {
    path: '/supervision',
    name: 'supervision',
    component: Layout,
    meta: { title: '党员质量监督岗',icon:"iconfont icon-dangyuan1" },
    redirect: 'supervision/supervision',
    children: [
      {
        path: 'supervision',
        component: () => import('@/views/supervision/manager-supervision.vue'),
        meta: { title: '管理监督岗',keepAlive: true}
      },
      {
        path: 'data-statisical',
        component: () => import('@/views/supervision/data-statisical.vue'),
        meta: { title: '数据统计'}
      },
      {
        path: '/',
        meta: { title: '成员列表'},
        redirect: 'member-list',
        component: () => import('@/views/supervision/member-manager'),
        children:[
          {
            path: 'member-list',
            component: () => import('@/views/supervision/member-manager/member-list.vue'),
            meta: { title: '成员列表',keepAlive:true},
            hidden:true
          },
          {
            path: 'list-detail',
            name: 'managerList-detail',
            component: () => import('@/views/supervision/member-manager/list-detail.vue'),
            meta: { title: '列表详情'},
            hidden:true
          },
        ]
      },
      {
        path: 'supervision-detail',
        name: 'supervision-detail',
        component: () => import('@/views/supervision/supervision-detail.vue'),
        meta: { title: '监督岗详情'},
        hidden: true
      },
    ]
  },
  {
    path: '/*',
    name: '404',
    component: () => import('@/views/error/NotFound.vue'),
    hidden:true
  },
]

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}



const router = new VueRouter({
  mode: process.env.NODE_ENV === 'production' ? 'history' : 'hash',
  routes
})

export default router
