import { login,logout,getUserInfo } from "@/api"
import { setToken,removeToken,getToken } from "@/utils/auth"
import router from '@/router'

const actions = {
  /**
   *
   * 用户登录
   */
  async userLogin({commit,state},formData) {
    try{
      const res = await login(formData)
      const token = res.data.data;
      commit("setToken",token);
      const info = await getUserInfo();
      const userInfo = info.data.data;
      localStorage.setItem("userInfo", JSON.stringify(userInfo));
      localStorage.setItem("group_id", userInfo.id);
      router.push({
        path:'/home'
      })
    }catch(e) {
      console.log(e)
    }
  },
  /**
   *
   * 用户退出
   */
  async userLogout({commit,state}) {
    try{
      await logout()
      commit("removeToken")
      localStorage.removeItem("group_id");
      localStorage.removeItem("userInfo");
      router.replace({
        path:'/login'
      })
    }catch(e) {
      console.log(e)
    }
  }
}


const mutations = {
  setToken(state,token) {
    state.token = token;
    setToken(token)
  },
  removeToken(state){
    state.token = '';
    removeToken()
  }
}

const state = {
  token: getToken() || '',
  userInfo: "",
}


export default {
  namespaced: true,
  state,
  mutations,
  actions
}
