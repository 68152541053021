<template>
  <div class="menubar">
   <el-scrollbar>
     <el-menu
       :default-active="activeRoute"
       class="el-menu-vertical-demo"
       background-color="#E32322"
       :unique-opened="true"
       active-text-color="#ffd04b"
       router
     >
       <menu-item class="silde"
         v-for="item in menuList"
         :key="item.path"
         :item="item"
         :basePath="item.path"
       ></menu-item>
     </el-menu>
   </el-scrollbar>
  </div>
</template>

<script>
import MenuItem from './MenuItem'
export default {
  name: 'MenuBar',
  data(){
    return {
      menuList: []
    }
  },
  components:{
    MenuItem
  },
  created () {
    this.menuList = this.$router.options.routes
  },
  computed:{
    activeRoute(){
      return this.$route.path
    }
  }
}
</script>

<style lang="scss" scoped>
  .menubar{
    width: 200px;
    .el-menu-vertical-demo{
      border: 0;
    }
  }
  .silde{
    border-bottom: 1px solid #fff;
    &:first-of-type{
      border: 0;
    }
  }

  ::v-deep .el-icon-arrow-down{
    color: #ffff;
    font-size: 15px;
  }
</style>
