import axios  from 'axios';
import qs from 'qs';
import { Message } from 'element-ui';
import router from '../router'

const request = axios.create({
  baseURL: '/web',
  headers:{
    "Content-Type": "application/x-www-form-urlencoded",
    "X-Requested-With": "XMLHttpRequest"
  },
  transformRequest:[
    (data) => {
      if(data instanceof FormData || Object.prototype.toString.call(data) === "[object FormData]") {
        return data;
      }
      return qs.stringify(data, {
        allowDots: false,
      });
    }
  ]
})



// 添加请求拦截器
request.interceptors.request.use(
  (config) => {
    config.headers.Authorization = localStorage.getItem("token") ||  '',
    config.headers["X-Requested-With"] = "XMLHttpRequest"
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);

//添加响应拦截
request.interceptors.response.use(response => {
  if(response.status === 200) {
    return Promise.resolve(response)
  }else{
    errorHandle(response.status,response.data.message)
    return Promise.reject(response)
  }
},error => {
  const { response } = error;
  if(response) {
    errorHandle(response.status, response.data.message);
    return Promise.reject(response);
  }else {
    return Promise.reject(error);
  }
})



//错误提示
const errorMessage = msg => {
  Message({
    type:"error",
    message: msg,
    duration: 1500
  })
}

/**
 * 返回登录页面
 */
const toLogin = (time = 1000) => {
  setTimeout(() => {
    router.replace({
      path:'/login',
      query:{
        redirect: router.currentRoute.fullPath
      }
    })
  },time)
  // 清除浏览器缓存
  const storeageInfo = ["token","userInfo"];
  storeageInfo.forEach(item => {
    localStorage.removeItem(item)
  })
}

/**
 * 错误判断
 */
const errorHandle = (status, message) => {
  // 状态码判断
  switch (status) {
    case 400:
      errorMessage(message);
      // toLogin();
      break;
    case 401:
      errorMessage(message);
      toLogin();
      break;
    // 403 token过期
    // 清除token并跳转登录页
    case 403:
      errorMessage(message);
      toLogin();
      break;
    // 404请求不存在
    case 404:
      errorMessage(message || "找不到资源!!!");
      break;
    default:
      console.log(message);
  }
}


export default request
